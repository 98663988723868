<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,

  props: ["chartData"],

  mounted() {
    this.renderChart({
      labels: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      datasets: this.chartData,
    });
  },
};
</script>

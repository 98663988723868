<template>
    <div class="Login-page">
        <!-- Content Tabs -->
        <form @submit.prevent="submitForm">
            <div class="form-tab">
                <div class="row">
                    <!-- START:: MOBILE NUMBER -->
                    <div class="col-lg-12">
                        <div class="form-group form-group-custom">
                            <!-- Dropdown  -->
                            <div class="dropdown-countries">
                                <div
                                    class="
                                        number-show
                                        d-flex
                                        align-items-center
                                        justify-content-center
                                    "
                                    @click="showNmber = !showNmber"
                                >
                                    <img :src="selectedCountry.flag" alt="" />
                                    <i class="fas fa-chevron-down"></i>
                                    <p>{{ selectedCountry.key }}</p>
                                </div>

                                <transition name="fade" mode="out-in">
                                    <ul v-if="showNmber">
                                        <li
                                            v-for="country in countries"
                                            :key="country.id"
                                            @click="
                                                chooseKey(
                                                    country.key,
                                                    country.flag
                                                )
                                            "
                                            class="
                                                d-flex
                                                align-items-center
                                                justify-content-between
                                            "
                                        >
                                            <img
                                                :src="country.flag"
                                                width="40px"
                                                height="25px"
                                            />
                                            <p>+{{ country.key }}</p>
                                        </li>
                                    </ul>
                                </transition>
                            </div>
                            <!-- Dropdown  -->
                            <input
                                type="tel"
                                class="form-control mobile-input"
                                v-model="logData.username"
                                placeholder="5XXXXXXXX"
                                name="mobile"
                            />
                            <label class="label-form">
                                {{ $t("mobile_number") }}
                            </label>
                        </div>
                    </div>
                    <!-- END:: MOBILE NUMBER -->

                    <!-- START:: PASSWORD -->
                    <div class="col-lg-12">
                        <div class="form-group form-group-custom">
                            <span
                                class="showPassword"
                                v-if="visibility == 'password'"
                                @click="showPassword"
                            >
                            </span>
                            <span
                                class="hidePassword"
                                v-else
                                @click="hidePassword"
                            >
                            </span>
                            <input
                                :type="visibility"
                                name="password"
                                class="form-control"
                                v-model="logData.password"
                            />
                            <label class="label-form">
                                {{ $t("password") }}
                            </label>
                        </div>
                    </div>
                    <!-- END:: PASSWORD -->

                    <!-- START:: REMEMBER ME -->
                    <!-- <div class="col-lg-12">
            <div class="checkbox-custom">
              <input
                type="checkbox"
                name="check"
                id="check"
                class="form-check-input"
              />
              <label for="check"> {{ $t("remember_me") }} </label>
            </div>
          </div> -->
                    <!-- END:: REMEMBER ME -->

                    <!-- START:: SIGN IN BUTTON -->
                    <div class="col-lg-12">
                        <div class="main-btn">
                            <button>
                                {{ $t("sign_in") }}
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                    v-if="isWaiting"
                                ></span>
                            </button>
                        </div>
                    </div>
                    <!-- END:: SIGN IN BUTTON -->

                    <!-- START:: RESET PASSWORD ROUTE -->
                    <div class="col-lg-12">
                        <div class="reset-link">
                            <router-link to="/reset-password-phone-number">
                                {{ $t("forgot_password") }}
                            </router-link>
                        </div>
                    </div>
                    <!-- END:: RESET PASSWORD ROUTE -->

                    <div class="register-link">
                        <p>
                            {{ $t("have_acc_question") }}
                            <router-link to="/register">
                                {{ $t("sign_up") }}
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Login",
    data() {
        return {
            isWaiting: false,

            visibility: "password",
            yourValue: "",
            //   number
            showNmber: false,

            countries: [],
            selectedCountry: {
                key: "",
                flag: "",
            },

            //   data
            logData: {
                username: "",
                password: "",
            },

            data: new FormData(),
        };
    },

    methods: {
        chooseKey(key, flag) {
            this.showNmber = false;
            this.selectedCountry = {
                key,
                flag,
            };
            this.logData.username = "";
        },

        showPassword() {
            this.visibility = "text";
        },

        hidePassword() {
            this.visibility = "password";
        },

        getCountriesKies() {
            axios
                .get("countries", {
                    headers: {
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.countries = res.data.data;

                    this.selectedCountry.key = res.data.data[0].key;
                    this.selectedCountry.flag = res.data.data[0].flag;
                });
        },

        submitForm() {
            this.isWaiting = true;

            if (this.logData.username == "") {
                this.$iziToast.error({
                    message: "Username Can't Be Empty",
                    position: "bottomCenter",
                    rtl: true,
                });
                this.isWaiting = false;
                return;
            }

            if (this.logData.password == "") {
                this.$iziToast.error({
                    message: "Passsword Can't Be Empty",
                    position: "bottomCenter",
                    rtl: true,
                });
                this.isWaiting = false;
                return;
            }

            this.data.append(
                "username",
                this.selectedCountry.key + this.logData.username
            );
            this.data.append("password", this.logData.password);

            axios
                .post("login", this.data, {
                    headers: {
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.isWaiting = false;
                    if (res.data.status == "success") {
                        localStorage.setItem(
                            "user_name",
                            res.data.data.fullname
                        );
                        localStorage.setItem("user_phone", res.data.data.phone);
                        localStorage.setItem("user_img", res.data.data.image);
                        localStorage.setItem("user_email", res.data.data.email);
                        localStorage.setItem(
                            "business_type",
                            res.data.data.business_type.name
                        );
                        localStorage.setItem("user_token", res.data.data.token);
                        if (res.data.admin_accept == 1) {
                            localStorage.setItem("accepted", 1);
                        }

                        if (res.data.data.user_type == "business_owner" || res.data.data.user_type == "business_owner_seller") {
                            this.$router.replace("/dashboard");
                        } else {
                            this.$iziToast.error({
                                message:"Login From Mobile App To Continue As Branch",
                                position: "bottomCenter",
                                rtl: true,
                            });
                            this.isWaiting = false;
                            return;
                        }
                    }
                })
                .catch((error) => {
                    this.isWaiting = false;
                    if (error.response.data.status == "fail") {
                        this.$iziToast.error({
                            message: error.response.data.message,
                            position: "bottomCenter",
                            rtl: true,
                        });
                        return;
                    }

                    localStorage.setItem(
                        "ver_phone",
                        this.selectedCountry.key + this.logData.username
                    );

                    if (error.response.data.is_active == false) {
                        this.$router.push("/verification/user");
                    }
                });
        },
    },

    mounted() {
        this.isWaiting = false;

        this.getCountriesKies();

        let user_token = localStorage.getItem("user_token");
        let user_is_accepted = localStorage.getItem("accepted");
        if (user_token && user_is_accepted != 0) {
            this.$router.push("/dashboard");
        }
    },
};
</script>

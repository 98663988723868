<template>
    <div class="profile">
        <form
            @submit.prevent="submitForm"
            class="d-flex justify-content-center align-items-center"
        >
            <div class="form-tab">
                <div class="row">
                    <!-- START:: LOGO -->
                    <div class="col-lg-12">
                        <div class="form-group">
                            <div class="upload-block uplaod-user">
                                <label class="text-center mb-3 fs-18">
                                    {{ $t("business_profile") }}
                                </label>
                                <div class="upload_wrap">
                                    <img
                                        :src="imgSrc"
                                        class="uploading-image"
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        @change="uploadImage"
                                        v-on:keydown.enter.prevent="editUser"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END:: LOGO -->

                    <!-- START:: NAME -->
                    <div class="col-lg-12">
                        <div class="form-group form-group-custom">
                            <input
                                type="text"
                                name="businessName"
                                class="form-control"
                                v-model="editData.name"
                            />
                            <label class="label-form">
                                {{ $t("business_name") }}
                            </label>
                        </div>
                    </div>
                    <!-- END:: NAME -->

                    <!-- START:: MOBILE NUMBER -->
                    <div class="col-lg-12">
                        <div class="form-group form-group-custom">
                            <!-- Dropdown  -->
                            <div class="dropdown-countries">
                                <div
                                    class="
                                        number-show
                                        d-flex
                                        align-items-center
                                        justify-content-center
                                    "
                                    @click="showNmber = !showNmber"
                                >
                                    <img :src="selectedCountry.flag" alt="" />
                                    <i class="fas fa-chevron-down"></i>
                                    <p>{{ selectedCountry.key }}</p>
                                </div>

                                <transition name="fade" mode="out-in">
                                    <ul v-if="showNmber">
                                        <li
                                            v-for="country in countries"
                                            :key="country.id"
                                            @click="
                                                chooseKey(
                                                    country.key,
                                                    country.flag
                                                )
                                            "
                                            class="
                                                d-flex
                                                align-items-center
                                                justify-content-between
                                            "
                                        >
                                            <img
                                                :src="country.flag"
                                                width="40px"
                                                height="25px"
                                            />
                                            <p>+{{ country.key }}</p>
                                        </li>
                                    </ul>
                                </transition>
                            </div>
                            <!-- Dropdown  -->
                            <input
                                type="tel"
                                class="form-control mobile-input"
                                v-model="editData.phone"
                                placeholder="5XXXXXXXX"
                                name="mobile"
                            />
                            <label class="label-form">
                                {{ $t("mobile_number") }}
                            </label>
                        </div>
                    </div>
                    <!-- END:: MOBILE NUMBER -->

                    <!-- START:: EMAIL -->
                    <div class="col-lg-12">
                        <div class="form-group form-group-custom">
                            <input
                                type="email"
                                class="form-control"
                                name="email"
                                v-model="editData.email"
                            />
                            <label class="label-form">
                                {{ $t("email") }}
                            </label>
                        </div>
                    </div>
                    <!-- END:: EMAIL -->

                    <!-- START:: MULTIPLE BRANCHES CHECKBOX -->
                    <div
                        class="col-12 mb-3"
                        v-if="business_type != 'central market'"
                    >
                        <div
                            class="form-check form-switch px-3 d-flex"
                            style="column-gap: 50px"
                        >
                            <label
                                class="form-check-label"
                                for="flexSwitchCheckChecked"
                            >
                                {{ $t("this_business_has_branches") }}
                            </label>

                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                v-model="has_multiple_branches"
                                @change="updateHasBranchesStatus"
                            />
                        </div>
                    </div>
                    <!-- END:: MULTIPLE BRANCHES CHECKBOX -->

                    <!-- START:: WORK TIMES -->
                    <div class="title_wraper">
                        <h5>{{ $t("working_time") }}</h5>
                    </div>

                    <div class="col-lg-6">
                        <div class="table_wraper">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td scope="row">#</td>
                                        <td>{{ $t("from") }}</td>
                                        <td>{{ $t("to") }}</td>
                                    </tr>

                                    <tr
                                        v-for="(item, index) in working_times"
                                        :key="item.day"
                                    >
                                        <td scope="row">{{ item.day }}</td>
                                        <td>
                                            <input
                                                type="time"
                                                placeholder="From"
                                                v-model="
                                                    working_times[index].from
                                                "
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="time"
                                                placeholder="To"
                                                v-model="
                                                    working_times[index].to
                                                "
                                                :format="formatTime"
                                            />
                                        </td>
                                    </tr>

                                    <!-- <tr>
                    <td scope="row">Saturday</td>
                    <td>
                      <input
                        type="time"
                        placeholder="From"
                        v-model="working_times[0].from"
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        placeholder="To"
                        v-model="working_times[0].to"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td scope="row">Sunday</td>
                    <td>
                      <input
                        type="time"
                        placeholder="From"
                        v-model="working_times[1].from"
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        placeholder="To"
                        v-model="working_times[1].to"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td scope="row">Monday</td>
                    <td>
                      <input
                        type="time"
                        placeholder="From"
                        v-model="working_times[2].from"
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        placeholder="To"
                        v-model="working_times[2].to"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td scope="row">Tuesday</td>
                    <td>
                      <input
                        type="time"
                        placeholder="From"
                        v-model="working_times[3].from"
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        placeholder="To"
                        v-model="working_times[3].to"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td scope="row">Wednesday</td>
                    <td>
                      <input
                        type="time"
                        placeholder="From"
                        v-model="working_times[4].from"
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        placeholder="To"
                        v-model="working_times[4].to"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td scope="row">Thursday</td>
                    <td>
                      <input
                        type="time"
                        placeholder="From"
                        v-model="working_times[5].from"
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        placeholder="To"
                        v-model="working_times[5].to"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td scope="row">Friday</td>
                    <td>
                      <input
                        type="time"
                        placeholder="From"
                        v-model="working_times[6].from"
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        placeholder="To"
                        v-model="working_times[6].to"
                      />
                    </td>
                  </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- END:: WORK TIMES -->

                    <!-- START:: CATEGORIES -->
                    <div
                        class="title_wraper mt-4"
                        v-if="business_type != 'central market'"
                    >
                        <h5>{{ $t("your_main_categories") }}</h5>
                    </div>

                    <div
                        class="col-12 mb-4"
                        v-if="business_type != 'central market'"
                    >
                        <div class="wraper">
                            <div class="row">
                                <div
                                    class="col-6 col-md-3 p-0 fadeIn"
                                    v-for="(category, index) in main_categories"
                                    :key="index"
                                >
                                    <div
                                        class="
                                            form-group
                                            append_inputs_wraper
                                            me-2
                                            my-2
                                        "
                                    >
                                        <input
                                            type="text"
                                            placeholder="Write Here"
                                            v-model="category.name"
                                        />
                                        <button
                                            type="button"
                                            class="
                                                input-delete
                                                btn
                                                text-danger
                                                p-0
                                            "
                                            @click="
                                                deleteSieze(index, category.id)
                                            "
                                        >
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </div>

                                <div
                                    class="
                                        col-6 col-md-3
                                        p-0
                                        d-flex
                                        align-items-center
                                        justify-content-start
                                    "
                                >
                                    <button
                                        type="button"
                                        @click="addSizes"
                                        class="
                                            button
                                            btn btn-outline-secondary
                                            rounded-pill
                                            ms-1
                                        "
                                    >
                                        <span class="mr-2">
                                            <span>
                                                <i class="fas fa-plus"></i>
                                            </span>
                                            {{ $t("add") }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END:: CATEGORIES -->

                    <div class="toggeler_wraper" @click="toggleEditPassword">
                        <div class="btn">
                            <u v-if="changePass">
                                {{ $t("cancel_change_password") }}
                            </u>
                            <u v-else> {{ $t("change_password") }} </u>
                        </div>
                    </div>

                    <transition name="fade" mode="in-out" v-if="changePass">
                        <div class="wraper">
                            <div class="row">
                                <!-- START:: CURRENT PASSWORD -->
                                <div class="col-lg-12">
                                    <div class="form-group form-group-custom">
                                        <input
                                            :type="visibility"
                                            name="password"
                                            class="form-control"
                                            v-model="editData.current_password"
                                        />
                                        <label class="label-form">
                                            {{ $t("current_password") }}
                                        </label>
                                    </div>
                                </div>
                                <!-- END:: CURRENT PASSWORD -->

                                <!-- START:: NEW PASSWORD -->
                                <div class="col-lg-6">
                                    <div class="form-group form-group-custom">
                                        <span
                                            class="showPassword"
                                            v-if="visibility == 'password'"
                                            @click="showPassword"
                                        >
                                        </span>
                                        <span
                                            class="hidePassword"
                                            v-else
                                            @click="hidePassword"
                                        >
                                        </span>
                                        <input
                                            :type="visibility"
                                            name="password"
                                            class="form-control"
                                            v-model="editData.new_password"
                                        />
                                        <label class="label-form">
                                            {{ $t("new_password") }}
                                        </label>
                                    </div>
                                </div>
                                <!-- END:: NEW PASSWORD -->

                                <!-- START:: CONFIRM PASSWORD -->
                                <div class="col-lg-6">
                                    <div class="form-group form-group-custom">
                                        <input
                                            :type="visibility"
                                            name="password"
                                            class="form-control"
                                            v-model="editData.confirm_password"
                                        />
                                        <label class="label-form">
                                            {{ $t("confirm_password") }}
                                        </label>
                                    </div>
                                </div>
                                <!-- START:: CONFIRM PASSWORD -->
                            </div>
                        </div>
                    </transition>

                    <!-- START:: SUBMIT BUTTON -->
                    <div class="col-lg-12 mt-3">
                        <div class="main-btn">
                            <button>
                                {{ $t("save") }}
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                    v-if="isWaiting"
                                ></span>
                            </button>
                        </div>
                    </div>
                    <!-- END:: SUBMIT BUTTON -->
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

export default {
    name: "profile",
    data() {
        return {
            isWaiting: false,

            business_type: localStorage.getItem("business_type").toLowerCase(),
            has_multiple_branches: false,

            imgSrc: null,
            attatch: "",
            beforeUpload: true,
            afterUpload: false,
            // currentPassVisibility: "password",
            visibility: "password",
            yourValue: "",
            changePass: false,
            //   number
            showNmber: false,

            countries: [],
            selectedCountry: {
                key: "",
                flag: "",
            },

            // START:: PROFILE DATA
            profile_data: null,
            // END:: PROFILE DATA

            //   data
            working_times: [
                {
                    day: "Saturday",
                    id: null,
                    order: 1,
                    from: null,
                    to: null,
                },
                {
                    day: "Sunday",
                    id: null,
                    order: 2,
                    from: null,
                    to: null,
                },
                {
                    day: "Monday",
                    id: null,
                    order: 3,
                    from: null,
                    to: null,
                },
                {
                    day: "Tuesday",
                    id: null,
                    order: 4,
                    from: null,
                    to: null,
                },
                {
                    day: "Wednesday",
                    id: null,
                    order: 5,
                    from: null,
                    to: null,
                },
                {
                    day: "Thursday",
                    id: null,
                    order: 6,
                    from: null,
                    to: null,
                },
                {
                    day: "Friday",
                    id: null,
                    order: 7,
                    from: null,
                    to: null,
                },
            ],

            updated_working_times: [
                {
                    day: "Saturday",
                    id: null,
                    order: 1,
                    from: null,
                    to: null,
                },
                {
                    day: "Sunday",
                    id: null,
                    order: 2,
                    from: null,
                    to: null,
                },
                {
                    day: "Monday",
                    id: null,
                    order: 3,
                    from: null,
                    to: null,
                },
                {
                    day: "Tuesday",
                    id: null,
                    order: 4,
                    from: null,
                    to: null,
                },
                {
                    day: "Wednesday",
                    id: null,
                    order: 5,
                    from: null,
                    to: null,
                },
                {
                    day: "Thursday",
                    id: null,
                    order: 6,
                    from: null,
                    to: null,
                },
                {
                    day: "Friday",
                    id: null,
                    order: 7,
                    from: null,
                    to: null,
                },
            ],

            main_categories: [],

            editData: {
                business_logo: null,
                name: null,
                phonecode: null,
                phone: null,
                email: null,
                current_password: "",
                new_password: "",
                confirm_password: "",
            },

            data: new FormData(),
        };
    },


    
    methods: {
        toggleEditPassword() {
            this.changePass = !this.changePass;
        },
        // upload image
        uploadImage(e) {
            if (e.target.files[0] != null) {
                this.data.append("business_logo", e.target.files[0]);
            }
            this.editData.business_logo = e.target.files[0];
            this.imgSrc = URL.createObjectURL(this.editData.business_logo);
        },

        // Choose Key
        chooseKey(key, flag) {
            this.showNmber = false;
            this.selectedCountry = {
                key,
                flag,
            };
            this.editData.phone = "";
        },
        // Show Password
        showPassword() {
            // this.currentPassVisibility = "text";
            this.visibility = "text";
        },
        // Hidden Password
        hidePassword() {
            // this.currentPassVisibility = "password";
            this.visibility = "password";
        },

        // START:: AXIOS GET COUNTRIES
        getCountriesKies() {
            axios
                .get("countries", {
                    headers: {
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.countries = res.data.data;

                    this.selectedCountry.key = res.data.data[0].key;
                    this.selectedCountry.flag = res.data.data[0].flag;
                });
        },
        // END:: AXIOS GET COUNTRIES

        handleSwitch() {
            if (this.has_multiple_branches == true) {
                return 1;
            } else if (this.has_multiple_branches == false) {
                return 0;
            }
        },

        addSizes() {
            this.main_categories.push({
                categoryName: "",
            });
        },

        deleteSieze(index, catId) {
            const deleteCat = new FormData();
            deleteCat.append("id[0]", catId);
            axios
                .post(
                    "business_owner/shop_description/delete_product_category",
                    deleteCat,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "user_token"
                            )}`,
                            "Accept-Language":
                                localStorage.getItem("dashboard_lang"),
                            "cache-control": "no-cache",
                            "Content-type": "application/json",
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {
                    if (res.data.status == "success") {
                        this.main_categories.splice(index, 1);
                    }
                });
        },

        formatTime(time) {
            return moment(time, "hh:mm A").format("HH:mm");
        },

        // START:: GET PROFILE DATA
        getProfileData() {
            axios
                .get("business_owner/shop_description", {
                    headers: {
                        Authorization: `Bearer${localStorage.getItem(
                            "user_token"
                        )}`,
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    // console.log(res.data.data.working_times);
                    // START:: SET PROFILE DATA
                    this.imgSrc = res.data.data.image;
                    this.editData.name = res.data.data.business_name;
                    this.editData.phonecode = res.data.data.phonecode;
                    this.editData.phone = res.data.data.mobile_number;
                    this.editData.email = res.data.data.email;
                    if (res.data.data.main_categories == null) {
                        this.main_categories = [];
                    } else {
                        this.main_categories = res.data.data.main_categories;
                    }

                    this.has_multiple_branches = res.data.data.has_branches;

                    this.working_times.forEach((element) => {
                        res.data.data.working_times.forEach( (responseElement) => {
                            if (element.order == responseElement.order) {
                                element.id = responseElement.id;

                                if ( responseElement.from == null || responseElement.from == "" ) {
                                    element.from = null;
                                } else {
                                    element.from = this.formatTime( responseElement.from );
                                    // element.id = this.responseElement.id;
                                }

                                if ( responseElement.to == null || responseElement.to == "" ) {
                                    element.to = null;
                                } else {
                                    element.to = this.formatTime( responseElement.to);
                                    // element.id = this.responseElement.id;
                                }
                            }
                        });
                        // console.log(element);
                    });

                    // res.data.data.working_times.forEach((responseElement) => {
                    //   console.log(responseElement);
                    // });
                    // END:: SET PROFILE DATA
                });
        },
        // END:: GET PROFILE DATA

        // START:: CHANGE  HAS BRANCHES STATE 
        updateHasBranchesStatus() {
            axios
                .get(`business_owner/has_branch?status=${this.handleSwitch()}`, {
                    headers: {
                        Authorization: `Bearer${localStorage.getItem("user_token")}`,
                        "Accept-Language": localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then( res => {
                    if( res.data.status  == "success" ) {
                        this.$iziToast.success({
                            message: res.data.message,
                            position: "bottomCenter",
                            rtl: true,
                        });
                    }
                })
        },
        // END:: CHANGE  HAS BRANCHES STATE 

        submitForm() {
            this.isWaiting = true;

            this.data.append("name", this.editData.name);

            // if (this.editData.phone.startsWith(this.selectedCountry.key)) {
            //   this.data.append("phone", this.editData.phone);
            // } else {
            // }

            this.data.append("phonecode", this.selectedCountry.key);
            this.data.append("phone", this.editData.phone);

            this.data.append("email", this.editData.email);
            this.data.append(
                "current_password",
                this.editData.current_password
            );
            this.data.append("new_password", this.editData.new_password);

            if (this.main_categories != null) {
                var catValues = this.main_categories;
                catValues.forEach((item, index) => {
                    var catText = [];
                    catText.push(item.name);
                    this.data.append(
                        "main_categories[" + index + "]",
                        item.name
                    );
                });
            }

            var timesValues = this.working_times;
            timesValues.forEach((item, index) => {
                var times = [];
                times.push({
                    id: item.id,
                    order: index + 1,
                    day: item.day,
                    from: item.from,
                    to: item.to,
                });

                this.data.append("working_times[" + index + "][id]", item.id);
                this.data.append("working_times[" + index + "][order]",index + 1);
                this.data.append("working_times[" + index + "][day]", item.day);

                if (item.from == "" || item.from == null) {
                    this.data.append("working_times[" + index + "][from]","");
                } else {
                    this.data.append("working_times[" + index + "][from]",item.from);
                }

                if (item.to == "" || item.to == null) {
                    this.data.append("working_times[" + index + "][to]", "");
                } else {
                    this.data.append("working_times[" + index + "][to]",item.to);
                }
            });

            axios
                .post("business_owner/shop_description/update", this.data, {
                    headers: {
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": `multipart/form-data;`,
                        Authorization: `Bearer ${localStorage.getItem(
                            "user_token"
                        )}`,
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.isWaiting = false;
                    localStorage.setItem("user_name",res.data.data.business_name);
                    localStorage.setItem("user_phone",res.data.data.mobile_number);
                    localStorage.setItem("user_email", res.data.data.email);
                    localStorage.setItem("user_img", res.data.data.image);
                    if (res.data.status != "fail") {
                        // location.reload();
                        this.getProfileData();
                    }
                    this.$iziToast.success({
                        message: res.data.message,
                        position: "bottomCenter",
                        rtl: true,
                    });
                })
                .catch((error) => {
                    this.isWaiting = false;
                    this.$iziToast.error({
                        message: error.response.data.message,
                        position: "bottomCenter",
                        rtl: true,
                    });
                });
        },
    },

    mounted() {
        this.isWaiting = false;
        this.getProfileData();
        this.getCountriesKies();
    },
};
</script>

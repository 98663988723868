<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,

  props: ["chartData"],

  mounted() {
    this.renderChart({
      labels: this.chartData.labels,

      datasets: this.chartData.datasets,
    });
  },
};
</script>

<template>
    <div class="contact_us">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-6">
                    <div class="contact_card_wraper">
                        <!-- START:: LOADER -->
                        <transition name="fade" mode="in-out">
                            <LoaderScreen v-if="isLoading" />
                        </transition>
                        <!-- END:: LOADER -->

                        <!-- START:: CONTACT MAP -->
                        <div class="map_wraper">
                            <div class="large-map">
                                <GmapMap
                                    :center="mapPosition"
                                    :zoom="7"
                                    map-type-id="terrain"
                                >
                                    <GmapMarker
                                        :position="mapPosition"
                                        :clickable="true"
                                        :draggable="true"
                                        :icon="{
                                            url: require('../../../assets/images/icons/marker.png'),
                                        }"
                                    />
                                    />
                                </GmapMap>
                            </div>
                        </div>
                        <!-- END:: CONTACT MAP -->

                        <!-- START:: CONTACT TITLE -->
                        <div class="title_wraper">
                            <h3>{{ $t("reach_out_to_us") }}</h3>
                        </div>
                        <!-- END:: CONTACT TITLE -->

                        <!-- START:: CONTACT FORM -->
                        <div class="contact_form">
                            <form @submit.prevent="submitForm">
                                <div class="form-group form-group-custom my-4">
                                    <input
                                        type="text"
                                        name="fullName"
                                        class="form-control"
                                        v-model="contactData.name"
                                    />
                                    <label class="label-form">
                                        {{ $t("full_name") }}
                                    </label>
                                </div>

                                <div class="form-group form-group-custom">
                                    <!-- Dropdown  -->
                                    <div class="dropdown-countries">
                                        <div
                                            class="
                                                number-show
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                            "
                                            @click="showNmber = !showNmber"
                                        >
                                            <img
                                                :src="selectedCountry.flag"
                                                alt=""
                                            />
                                            <i class="fas fa-chevron-down"></i>
                                            <p>{{ selectedCountry.key }}</p>
                                        </div>

                                        <transition name="fade" mode="out-in">
                                            <ul v-if="showNmber">
                                                <li
                                                    v-for="country in countries"
                                                    :key="country.id"
                                                    @click="
                                                        chooseKey(
                                                            country.key,
                                                            country.flag
                                                        )
                                                    "
                                                    class="
                                                        d-flex
                                                        align-items-center
                                                        justify-content-between
                                                    "
                                                >
                                                    <img
                                                        :src="country.flag"
                                                        width="40px"
                                                        height="25px"
                                                    />
                                                    <p>+{{ country.key }}</p>
                                                </li>
                                            </ul>
                                        </transition>
                                    </div>
                                    <!-- Dropdown  -->
                                    <input
                                        type="tel"
                                        class="form-control mobile-input"
                                        v-model="contactData.phone"
                                        placeholder="5XXXXXXXX"
                                        name="mobile"
                                    />
                                    <label class="label-form">
                                        {{ $t("mobile_number") }}
                                    </label>
                                </div>

                                <div class="form-group form-group-custom my-4">
                                    <textarea
                                        name="message"
                                        rows="7"
                                        class="form-control"
                                        v-model="contactData.message"
                                    ></textarea>
                                    <label class="label-form">
                                        {{ $t("your_Message") }}
                                    </label>
                                </div>

                                <div class="col-lg-12">
                                    <div class="main-btn">
                                        <button>
                                            {{ $t("send") }}
                                            <span
                                                class="
                                                    spinner-border
                                                    spinner-border-sm
                                                "
                                                role="status"
                                                aria-hidden="true"
                                                v-if="isWaiting"
                                            ></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- END:: CONTACT FORM -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.vue-map-container {
    height: 300px;
}
</style>

<script>
import axios from "axios";
import LoaderScreen from "../../ui/LoaderScreen.vue";

export default {
    name: "contact-us",

    components: {
        LoaderScreen,
    },

    data() {
        return {
            isLoading: true,
            isWaiting: false,

            //   number
            showNmber: false,
            numbers: {
                number: "+966",
                flag: "",
            },

            countries: [],
            selectedCountry: {
                key: "",
                flag: "",
            },

            //   data
            contactData: {
                name: "",
                phone: "",
                message: "",
            },
            data: new FormData(),
            //diaa
            contactInfo: "",
            // map
            mapPosition: {
                lat: null,
                lng: null,
            },
        };
    },

    methods: {
        // START:: AXIOS GET COUNTRIES
        getCountriesKies() {
            axios
                .get("countries", {
                    headers: {
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.countries = res.data.data;

                    this.selectedCountry.key = res.data.data[0].key;
                    this.selectedCountry.flag = res.data.data[0].flag;
                });
        },
        // END:: AXIOS GET COUNTRIES

        // Choose Key
        chooseKey(key, flag) {
            this.showNmber = false;
            this.selectedCountry = {
                key,
                flag,
            };
            this.contactData.phone = "";
        },

        submitForm() {
            this.isWaiting = true;

            if (this.contactData.name == "") {
                this.isWaiting = false;
                this.$iziToast.error({
                    message: this.$t("name_can't_be_empty"),
                    position: "bottomCenter",
                    rtl: true,
                });
                return;
            }

            if (this.contactData.phone == "") {
                this.isWaiting = false;
                this.$iziToast.error({
                    message: this.$t("phone_can't_be_empty"),
                    position: "bottomCenter",
                    rtl: true,
                });
                return;
            }

            if (this.contactData.message == "") {
                this.isWaiting = false;
                this.$iziToast.error({
                    message: this.$t("message_can't_be_empty"),
                    position: "bottomCenter",
                    rtl: true,
                });
                return;
            }

            this.data.append("name", this.contactData.name);
            this.data.append(
                "phone",
                this.selectedCountry.key + this.contactData.phone
            );
            this.data.append("message", this.contactData.message);
            axios
                .post("contact_us", this.data)
                .then((res) => {
                    this.isWaiting = false;
                    if (res.data.status == "success") {
                        this.$iziToast.success({
                            message: "Your Message Sent Successfuly",
                            position: "bottomCenter",
                            rtl: true,
                        });
                    }
                    this.contactData.name = "";
                    this.contactData.phone = "";
                    this.contactData.message = "";
                })
                .catch((error) => {
                    this.isWaiting = false;
                    this.$iziToast.error({
                        message: error.response.data.message,
                        position: "bottomCenter",
                        rtl: true,
                    });
                });
        },
        //get contact data
        getContact() {
            axios
                .get("contact", {
                    "Accept-Language": localStorage.getItem("dashboard_lang"),
                    "cache-control": "no-cache",
                    "Content-type": "application/json",
                    Accept: "application/json",
                })
                .then((res) => {
                    this.contactInfo = res.data.data;
                    this.mapPosition.lat = parseInt(
                        this.contactInfo.map_api.lat
                    );
                    this.mapPosition.lng = parseInt(
                        this.contactInfo.map_api.lng
                    );
                });
        },
    },

    mounted() {
        this.isLoading = false;
        this.isWaiting = false;
        this.getCountriesKies();
        this.getContact();
        let user_token = localStorage.getItem("user_token");
        if (!user_token) {
            this.$router.push("/login");
        }
    },
};
</script>

<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,

  props: ["chartData"],

  mounted() {
    // START:: RENDERING CHART DATA
    this.renderChart({
      labels: this.chartData.labels,
      datasets: this.chartData.datasets,
      options: {
        scales: {
          y: {
            min: 0,
            max: 2000,
          },
        },
      },
    });
    // END:: RENDERING CHART DATA
  },
};
</script>

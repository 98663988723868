<template>
  <div class="message_wraper">
    <header-site></header-site>

    <section id="authSiteWraper">
      <div class="container d-flex justify-content-center align-items-center">
        <div class="message">
          <div class="title_wraper">
            <h4>{{ $t("thank_you") }}</h4>
          </div>

          <div class="text_wraper">
            Your Account Has Been Verified, Please Wait Admin Confirmation
          </div>

          <div class="loader_wraper">
            <div class="pill">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderSite from "@/components/pages/Header/HeaderSite";
import axios from "axios";

export default {
  name: "WaitingMessage",
  components: {
    HeaderSite,
  },

  methods: {
    getAcceptedValue() {
      axios
        .get("business_owner/shop_description", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            "Accept-Language": localStorage.getItem("dashboard_lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          localStorage.setItem("accepted", res.data.admin_accept);
        });
    },
  },

  mounted() {
    this.getAcceptedValue();

    let accepted = localStorage.getItem("accepted");
    if (accepted == 1) {
      this.$router.push("/business_data");
    }
  },
};
</script>

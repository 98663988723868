<template>
    <div class="Login-page">
        <header-site v-if="userVerification"></header-site>
        <dashboard-header v-if="branchVerification"></dashboard-header>

        <section id="authSiteWraper">
            <div class="container">
                <!-- Content Tabs -->
                <form @submit.prevent="submitForm">
                    <div class="form-tab crud-auth padding-all-15">
                        <div class="row p-3 p-md-0">
                            <div class="col-lg-12">
                                <div class="text-form-input">
                                    <h3>{{ $t("verification_code") }}</h3>
                                    <p>{{ $t("verify_your_number") }}</p>
                                </div>
                                <div class="form-group form-group-custom">
                                    <input
                                        type="tel"
                                        class="form-control text-center"
                                        style="letter-spacing: 2rem"
                                        v-model="verData.code"
                                        maxlength="4"
                                    />
                                    <label class="label-form">
                                        {{ ver_phone }}
                                    </label>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="countdown">
                                    <h6>{{ "0 : " + timerCount }}</h6>
                                    <p>
                                        {{ $t("didn’t_receive") }},
                                        <button
                                            id="resend_btn"
                                            type="button"
                                            @click="resendCode"
                                        >
                                            {{ $t("resend") }}
                                        </button>
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="main-btn m-0">
                                    <button>{{ $t("verify") }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import axios from "axios";
import HeaderSite from "@/components/pages/Header/HeaderSite";
import DashboardHeader from "../headerDashboard/DashboardHeader.vue";

export default {
    components: {
        HeaderSite,
        DashboardHeader,
    },

    data() {
        return {
            isWaiting: false,

            timerCount: 60,
            ver_phone: localStorage.getItem("ver_phone"),
            ver_type: this.$route.params.type,
            verData: {
                code: "",
                phone: localStorage.getItem("ver_phone"),
                device_token: "7485996",
                type: "ios",
            },
        };
    },

    computed: {
        userVerification() {
            return this.$route.path.includes("/user");
        },
        branchVerification() {
            return this.$route.path.includes("/branch");
        },
    },

    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }

                if (value > 0) {
                    document
                        .querySelector("#resend_btn")
                        .setAttribute("disabled", "disabled");
                } else {
                    document
                        .querySelector("#resend_btn")
                        .removeAttribute("disabled");
                }
            },

            immediate: true,
        },
    },

    methods: {
        submitForm() {
            this.isWaiting = true;

            if (this.ver_type == "reset-password") {
                let cashedVerCode = localStorage.getItem("reset_ver_code");
                if( this.verData.code == cashedVerCode ) {
                    this.$router.replace("/reset-password");
                } else {
                    this.$iziToast.error({
                        message: "Wrong Verification Code",
                        position: "bottomCenter",
                        rtl: true,
                    });
                    return;
                }
            } else {
                axios
                    .post("verify", this.verData, {
                        headers: {
                            "Accept-Language":
                                localStorage.getItem("dashboard_lang"),
                            "cache-control": "no-cache",
                            "Content-type": "application/json",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isWaiting = false;
                        localStorage.setItem("user_data", res.data.data);
                        localStorage.setItem(
                            "user_name",
                            res.data.data.fullname
                        );
                        localStorage.setItem("user_phone", res.data.data.phone);
                        localStorage.setItem("user_img", res.data.data.image);
                        localStorage.setItem("user_email", res.data.data.email);
                        localStorage.setItem(
                            "business_type",
                            res.data.data.business_type.name
                        );
                        localStorage.setItem("user_token", res.data.data.token);
                        if (res.data.status == "success") {
                            this.$router.replace(`${this.$route.path}/success`);
                            // this.$router.replace("/waiting");
                        }
                        localStorage.setItem("accepted", res.data.admin_accept);
                    });
            }
        },

        resendCode() {
            axios
                .post(
                    "send_code",
                    { phone: this.ver_phone },
                    {
                        headers: {
                            "Accept-Language":
                                localStorage.getItem("dashboard_lang"),
                            "cache-control": "no-cache",
                            "Content-type": "application/json",
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.$iziToast.success({
                        message: res.data.message,
                        position: "bottomCenter",
                        rtl: true,
                    });

                    if (this.ver_type == "reset-password") {
                        localStorage.setItem("reset_ver_code", res.data.dev_message)
                    }

                    this.timerCount = 60;
                })
                .catch((error) => {
                    this.$iziToast.error({
                        message: error.response.data.message,
                        position: "bottomCenter",
                        rtl: true,
                    });
                });
        },
    },

    mounted() {
        this.isWaiting = false;
    },
};
</script>

<template>
  <div
    class="not-found"
    data-aos="fade"
    data-aos-delay="0"
    data-aos-duration="700"
    data-aos-easing="ease-in-out"
    data-aos-once="false"
  >
    <div class="image">
      <span class="icon"><i class="fas fa-exclamation fa-lg"></i></span>
    </div>

    <h3>{{ title }}</h3>
    <!-- <h3>{{ $t("no_data") }}</h3> -->
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style>
.not-found {
  width: 100%;
  height: 400px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.not-found .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.not-found span.icon {
  font-size: 42px;
  color: #b18825;
}

.not-found h3 {
  font-size: 25px;
  color: #b18825;
  position: relative;
}
</style>

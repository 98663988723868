import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/HomeSite/home.vue";
import Landing from "../pages/HomeSite/LandingPage.vue";
import mainAuth from "../pages/Auth/mainAuth";
import Login from "../pages/Auth/Login.vue";
import Register from "../pages/Auth/Register.vue";
import ResetPasswordPhoneNumber from "../pages/Auth/ResetPasswordPhoneNumber.vue";
import ResetPassword from "../pages/Auth/ResetPassword.vue";
import verification from "../pages/Auth/verification.vue";
import BranchVerification from "../pages/dashboard/branches/BranchVerification.vue";
import SuccessMessage from "../pages/dashboard/SuccessMessage.vue";
import WaitingMessage from "../pages/dashboard/WaitingAccept.vue";
import BusinessData from "../pages/dashboard/BusinessData.vue";
import mainDashboard from "../pages/dashboard/mainDashboard";
import dashboardHome from "../pages/dashboard/dashboard-home.vue";
import Profile from "../pages/dashboard/Profile.vue";
import Branches from "../pages/dashboard/branches/Branches.vue";
import BranchDetails from "../pages/dashboard/branches/BranchDetails.vue";
import AddBranch from "../pages/dashboard/branches/AddBranch.vue";
import AboutUs from "../pages/dashboard/AboutUs.vue";
import Terms from "../pages/dashboard/Terms.vue";
import ContactUs from "../pages/dashboard/ContactUs.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/",
                name: "landing",
                component: Landing,
            },
        ],
    },
    {
        path: "/login",
        name: "mainAuth",
        component: mainAuth,
        children: [
            {
                path: "/login",
                name: "Login",
                component: Login,
            },
            {
                path: "/register",
                name: "Register",
                component: Register,
            },
        ],
    },
    {
        path: "/reset-password-phone-number",
        name: "ResetPasswordPhoneNumber",
        component: ResetPasswordPhoneNumber,
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/verification/:type",
        name: "Verification",
        component: verification,
    },
    {
        path: "/branch_verification/:phone",
        name: "BranchVerification",
        component: BranchVerification,
    },
    {
        path: "/verification/:type/success",
        name: "successMessage",
        component: SuccessMessage,
    },
    {
        path: "/waiting",
        name: "WaitingMessage",
        component: WaitingMessage,
    },
    {
        path: "/business_data",
        name: "businessData",
        component: BusinessData,
    },  
    {
        path: "/dashboard",
        name: "mainDashboard",
        component: mainDashboard,
        children: [
            {
                path: "/dashboard",
                name: "dashboard-home",
                component: dashboardHome,
            },
            {
                path: "/edit_profile",
                name: "profile",
                component: Profile,
            },
            {
                path: "/branches",
                name: "branches",
                component: Branches,
            },
            {
                path: "/branches/:id",
                name: "branchDetails",
                component: BranchDetails,
            },
            {
                path: "/addBranch",
                name: "addBranch",
                component: AddBranch,
            },
            {
                path: "/about",
                name: "about-us",
                component: AboutUs,
            },
            {
                path: "/terms",
                name: "terms",
                component: Terms,
            },
            {
                path: "/contact",
                name: "contact-us",
                component: ContactUs,
            },
        ],
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    routes,
});

export default router;

<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,

  props: ["chartData"],

  mounted() {
    this.renderChart({
      labels: this.chartData.labels,
      datasets: [
        {
          label: "TotalProducts",
          data: this.chartData.datasets,
          backgroundColor: this.chartData.backgroundColor,
        },
      ],
    });
  },
};
</script>

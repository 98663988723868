<template>
    <div class="mainHome">
        <!-- START:: LOADER -->
        <transition name="fade" mode="in-out">
            <LoaderScreen class="fixed_loader_wrapper" v-if="isLoading" />
        </transition>
        <!-- END:: LOADER -->

        <div class="container-fluid py-3">
            <div class="row justify-content-center align-items-center">
                <!-- START:: FILTER -->
                <div class="filter_btn_wraper col-12 my-2">
                    <button class="btn" @click="openFilterMenu">
                        <i class="fas fa-filter fa-lg"></i>
                    </button>
                </div>

                <div class="filter_menu_wraper">
                    <div class="title_wraper">
                        <h4>{{ $t("filter") }}</h4>
                        <div class="actions">
                            <button class="btn" @click="closeFilterMenu">
                                <i class="fas fa-times fa-lg"></i>
                            </button>

                            <button
                                class="btn text-danger"
                                @click="clearFilterInputs"
                            >
                                {{ $t("clear_all") }}
                            </button>
                        </div>
                    </div>

                    <form @submit.prevent="getFilterStatistics">
                        <div class="form-tab">
                            <div class="row">
                                <!-- <div class="col-12">
                                    <div class="form-group form-group-custom">
                                        <input
                                            type="date"
                                            class="form-control"
                                            v-model="filterStatistics.from"
                                        />
                                        <label class="label-form">
                                            {{ $t("from") }}
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12 my-3">
                                    <div class="form-group form-group-custom">
                                        <input
                                            type="date"
                                            class="form-control"
                                            v-model="filterStatistics.to"
                                        />
                                        <label class="label-form">
                                            {{ $t("to") }}
                                        </label>
                                    </div>
                                </div> -->

                                <div class="col-12">
                                    <div class="form-check radio_group">
                                        <input
                                            id="weekly"
                                            class="form-check-input"
                                            type="radio"
                                            name="filter_time"
                                            value="weekly"
                                            v-model="filterStatistics.time"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="weekly"
                                        >
                                            {{ $t("weekly") }}
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-check radio_group">
                                        <input
                                            id="monthly"
                                            class="form-check-input"
                                            type="radio"
                                            name="filter_time"
                                            value="monthly"
                                            v-model="filterStatistics.time"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="monthly"
                                        >
                                            {{ $t("monthly") }}
                                        </label>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-check radio_group">
                                        <input
                                            id="yearly"
                                            class="form-check-input"
                                            type="radio"
                                            name="filter_time"
                                            value="yearly"
                                            v-model="filterStatistics.time"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="yearly"
                                        >
                                            {{ $t("yearly") }}
                                        </label>
                                    </div>
                                </div>

                                <!-- START:: SUBMIT BUTTON -->
                                <div class="col-lg-12">
                                    <div class="main-btn">
                                        <button>
                                            {{ $t("search") }}
                                        </button>
                                    </div>
                                </div>
                                <!-- END:: SUBMIT BUTTON -->
                            </div>
                        </div>
                    </form>
                </div>
                <!-- END:: FILTER -->

                <!-- START:: TOTAL SALES CHART -->
                <div class="col-12 col-lg-8 my-2">
                    <div class="chart_card chart_card_large p-2">
                        <!-- START:: NOT FOUND -->
                        <!-- <NotFound :title="$t('no_statistics')"/> -->
                        <!-- END:: NOT FOUND -->

                        <template>
                            <div class="title_wraper">
                                <div class="number">
                                    {{ totalSalesFees }} RSA
                                </div>
                                <div class="title">{{ $t("total_sales") }}</div>
                            </div>

                            <TotalSales
                                :chartData="allChartsData.total_sales"
                                v-if="allChartsData"
                            />
                        </template>
                    </div>
                </div>
                <!-- END:: TOTAL SALES CHART -->

                <!-- START:: TOTAL PRODUCTS CHART -->
                <div class="col-12 col-lg-4 my-2">
                    <div class="chart_card p-2">
                        <template>
                            <div class="title_wraper">
                                <div class="number">
                                    {{ totalProductsNumber }}
                                </div>
                                <div class="title">
                                    {{ $t("total_products") }}
                                </div>
                            </div>

                            <TotalProducts
                                :chartData="allChartsData.total_products"
                                v-if="allChartsData"
                            />
                        </template>
                    </div>
                </div>
                <!-- END:: TOTAL PRODUCTS CHART -->

                <!-- START:: HISTORY TRAFIC CHART -->
                <div class="col-12 col-lg-8 my-2">
                    <div class="chart_card chart_card_large p-2">
                        <template>
                            <div class="title_wraper">
                                <div class="title">
                                    {{ $t("history_traffic") }}
                                </div>
                            </div>

                            <HistoryTrafic
                                :chartData="allChartsData.history_traffic"
                                v-if="allChartsData"
                            />
                        </template>
                    </div>
                </div>
                <!-- END:: HISTORY TRAFIC CHART -->

                <!-- START:: REVIEWS AND RATING CHART -->
                <div class="col-12 col-lg-4 my-2">
                    <div class="chart_card p-2">
                        <template>
                            <div class="title_wraper">
                                <div class="title">
                                    {{ $t("reviews_rating") }}
                                </div>
                            </div>

                            <ReviewsAndRating
                                :chartData="allChartsData.rates"
                                v-if="allChartsData"
                            />
                        </template>
                    </div>
                </div>
                <!-- START:: REVIEWS AND RATING CHART -->

                <!-- START:: TOTAL INCOMES CHART -->
                <div class="col-12 col-lg-6 my-2">
                    <div class="chart_card chart_card_large p-2">
                        <template>
                            <div class="title_wraper">
                                <div class="number">
                                    {{ totalIncomesFees }} RSA
                                </div>
                                <div class="title">
                                    {{ $t("total_incomes") }}
                                </div>
                            </div>

                            <TotalIncomes
                                :chartData="allChartsData.total_incomes"
                                v-if="allChartsData"
                            />
                        </template>
                    </div>
                </div>
                <!-- END:: TOTAL INCOMES CHART -->

                <!-- START:: TOP PRODUCTS TABLE -->
                <div class="col-12 col-lg-6 my-2">
                    <div class="chart_card chart_card_large p-2">
                        <template>
                            <div class="title_wraper">
                                <div class="title">
                                    {{ $t("top_products") }}
                                </div>
                            </div>

                            <TopProducts
                                :chartData="allChartsData.top_product"
                                v-if="allChartsData"
                            />
                        </template>
                    </div>
                </div>
                <!-- END:: TOP PRODUCTS TABLE -->
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING AXIOS
import axios from "axios";
// END:: IMPORTING AXIOS

// START:: IMPORTING COMPONENTS
import LoaderScreen from "../../ui/LoaderScreen.vue";
import TotalSales from "../../charts/totalSales.vue";
import TotalProducts from "../../charts/totalProducts.vue";
import HistoryTrafic from "../../charts/HistoryTrafic.vue";
import TotalIncomes from "../../charts/totalIncomes.vue";
import ReviewsAndRating from "../../charts/reviewsAndRating.vue";
import TopProducts from "../../charts/topProducts.vue";
// END:: IMPORTING COMPONENTS

// START:: IMPORTING NOT FOUND COMPONENT
// import NotFound from "../../ui/NotFound.vue";
// END:: IMPORTING NOT FOUND COMPONENT

export default {
    components: {
        LoaderScreen,
        TotalSales,
        TotalProducts,
        HistoryTrafic,
        TotalIncomes,
        ReviewsAndRating,
        TopProducts,
    },

    data() {
        return {
            isLoading: true,

            totalSalesFees: 0,
            totalIncomesFees: 0,
            totalProductsNumber: 0,

            // START:: CHART DATA
            allChartsData: null,
            // END:: CHART DATA

            filterStatistics: {
                from: null,
                to: null,
                time: null,
            },
        };
    },

    methods: {
        // START:: CONTROLING FLTER MENU
        openFilterMenu() {
            document
                .querySelector(".filter_menu_wraper")
                .classList.add("open_filter");
        },
        closeFilterMenu() {
            document
                .querySelector(".filter_menu_wraper")
                .classList.remove("open_filter");
        },
        // END:: CONTROLING FLTER MENU

        // START:: FILTER STATISTICS METHOD
        getFilterStatistics() {
            axios
                .get(
                    `business_owner/statistic?filter=${this.filterStatistics.time}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "user_token"
                            )}`,
                            "Accept-Language":
                                localStorage.getItem("dashboard_lang"),
                            "cache-control": "no-cache",
                            "Content-type": "application/json",
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {
                    this.allChartsData = res.data.data;
                    this.totalSalesFees = res.data.data.total_sales.total;
                    this.totalIncomesFees = res.data.data.total_incomes.total;
                    this.totalProductsNumber =
                        res.data.data.total_products.total;
                    this.closeFilterMenu();
                })
                .catch((error) => console.error(error));
        },
        // END:: FILTER STATISTICS METHOD

        // START:: CLEAR FILTER INPUTS METHOD
        clearFilterInputs() {
            this.filterStatistics.from = null;
            this.filterStatistics.to = null;
            this.filterStatistics.time = null;
        },
        // END:: CLEAR FILTER INPUTS METHOD

        // START:: GET CHART DATA
        getChartData() {
            axios
                .get("business_owner/statistic", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "user_token"
                        )}`,
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.allChartsData = res.data.data;
                    this.totalSalesFees = res.data.data.total_sales.total;
                    this.totalIncomesFees = res.data.data.total_incomes.total;
                    this.totalProductsNumber =
                        res.data.data.total_products.total;
                })
                .catch((error) => console.error(error));
        },
        // END:: GET CHART DATA
    },

    mounted() {
        this.isLoading = false;

        // START:: GET CHART DATA
        this.getChartData();
        // END:: GET CHART DATA

        localStorage.removeItem("ver_code");
        localStorage.removeItem("ver_phone");

        let user_token = localStorage.getItem("user_token");
        if (!user_token) {
            this.$router.push("/login");
        }
    },
};
</script>

<template>
    <div>
        <HeaderSite />
        <div class="Login-page">
            <section id="authSiteWraper">
                <div class="container">
                    <form @submit.prevent="validateResetPasswordInputs">
                        <div class="form-tab crud-auth padding-all-15">
                            <div class="text-form-input">
                                <h3>{{ $t("reset_password") }}</h3>
                            </div>
                            <div class="row p-3 p-md-0">
                                <!-- START::  NEW PASSWORD -->
                                <div class="col-lg-12">
                                    <div class="form-group form-group-custom">
                                        <span
                                            class="showPassword"
                                            v-if="
                                                new_password_visibility ==
                                                'password'
                                            "
                                            @click="
                                                showPassword('new_password')
                                            "
                                        >
                                        </span>
                                        <span
                                            class="hidePassword"
                                            v-else
                                            @click="
                                                hidePassword('new_password')
                                            "
                                        >
                                        </span>
                                        <input
                                            :type="new_password_visibility"
                                            name="password"
                                            class="form-control"
                                            v-model.trim="
                                                resetPassData.newPassword
                                            "
                                        />
                                        <label class="label-form">
                                            {{ $t("new_password") }}
                                        </label>
                                    </div>
                                </div>
                                <!-- END:: NEW  PASSWORD -->

                                <!-- START::  CONFIRM NEW PASSWORD -->
                                <div class="col-lg-12">
                                    <div class="form-group form-group-custom">
                                        <span
                                            class="showPassword"
                                            v-if="
                                                confirm_new_password_visibility ==
                                                'password'
                                            "
                                            @click="
                                                showPassword(
                                                    'confirm_new_password'
                                                )
                                            "
                                        >
                                        </span>
                                        <span
                                            class="hidePassword"
                                            v-else
                                            @click="
                                                hidePassword(
                                                    'confirm_new_password'
                                                )
                                            "
                                        >
                                        </span>
                                        <input
                                            id="confirm_new_password"
                                            :type="
                                                confirm_new_password_visibility
                                            "
                                            name="password"
                                            class="form-control"
                                            v-model.trim="
                                                resetPassData.confirmNewPassword
                                            "
                                        />
                                        <label class="label-form">
                                            {{ $t("confirm_new_pass") }}
                                        </label>
                                    </div>
                                </div>
                                <!-- END:: CONFIRM  NEW  PASSWORD -->

                                <div class="col-lg-12">
                                    <div class="main-btn m-0">
                                        <button>
                                            {{ $t("save") }}
                                            <span
                                                class="
                                                    spinner-border
                                                    spinner-border-sm
                                                "
                                                role="status"
                                                aria-hidden="true"
                                                v-if="isWaiting"
                                            ></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import HeaderSite from "@/components/pages/Header/HeaderSite";

export default {
    name: "ResetPassword",

    components: {
        HeaderSite,
    },

    data() {
        return {
            isWaiting: false,
            new_password_visibility: "password",
            confirm_new_password_visibility: "password",

            resetPassData: {
                newPassword: null,
                confirmNewPassword: null,
            },
        };
    },

    methods: {
        showPassword(id) {
            if (id == "new_password") {
                this.new_password_visibility = "text";
            } else if (id == "confirm_new_password") {
                this.confirm_new_password_visibility = "text";
            }
        },

        hidePassword(id) {
            if (id == "new_password") {
                this.new_password_visibility = "password";
            } else if (id == "confirm_new_password") {
                this.confirm_new_password_visibility = "password";
            }
        },

        // START:: VALIDATE RESET PASSWORD FORM INPUTS
        validateResetPasswordInputs() {
            if (this.resetPassData.newPassword == null) {
                this.$iziToast.error({
                    message: "New Password Can't Be Empty",
                    position: "bottomCenter",
                    rtl: true,
                });
                return;
            } else if (this.resetPassData.confirmNewPassword == null) {
                this.$iziToast.error({
                    message: "New Password Confirmation Can't Be Empty",
                    position: "bottomCenter",
                    rtl: true,
                });
                return;
            } else if (
                this.resetPassData.newPassword !=
                this.resetPassData.confirmNewPassword
            ) {
                this.$iziToast.error({
                    message: "Passwords Don't Match",
                    position: "bottomCenter",
                    rtl: true,
                });
                return;
            } else {
                this.submitResetPassword();
            }
        },
        // END:: VALIDATE RESET PASSWORD FORM INPUTS

        // START:: SUBMIT RESET PASSWORD METHOD
        submitResetPassword() {
            this.isWaiting = true;

            let theData = new FormData();
            theData.append("phone", localStorage.getItem("reset_pass_phone"));
            theData.append("password", this.resetPassData.newPassword);
            theData.append("code", localStorage.getItem("reset_ver_code"));

            axios
                .post("reset_password", theData, {
                    headers: {
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": `multipart/form-data;`,
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.isWaiting = false;
                    if (res.data.status) {
                        this.$iziToast.success({
                            message: res.data.message,
                            position: "bottomCenter",
                            rtl: true,
                        });
                        this.isWaiting = false;
                        this.$router.replace("/login");
                        localStorage.removeItem("reset_ver_code");
                        localStorage.removeItem("reset_pass_phone");
                    }
                })
                .catch( () => {
                    this.isWaiting = false;
                }) 
        },
        // START:: SUBMIT RESET PASSWORD METHOD
    },
};
</script>

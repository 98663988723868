<template>
    <div class="terms_wrapper">
        <BaseModal :show="modalApperanceData" @click="controleModalApperance">
            <template #modal>
                <div class="terms_card">
                    <h2>
                        <slot name="title"> </slot>
                    </h2>

                    <div class="text" v-html="termsText"></div>

                    <div class="don_btn">
                        <slot name="don_btn"></slot>
                    </div>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script>
import axios from "axios";

// START:: IMPORTING BASE MODAL
import BaseModal from "./BaseModal.vue";
// END:: IMPORTING BASE MODAL

export default {
    name: "TermsModal",
    data() {
        return {
            // START:: LOADER WAIT
            isWaitingRequest: false,
            // END:: LOADER WAIT

            // START:: TERMS DATA
            termsText: null,
            // END:: TERMS DATA
        };
    },
    components: {
        BaseModal,
    },
    props: ["modalApperanceData"],

    emits: ["controleModalApperance"],

    methods: {
        // START:: CONTROLE MODAL APPERANCE
        controleModalApperance() {
            this.$emit("controleModalApperance");
        },
        // END:: CONTROLE MODAL APPERANCE

        // START:: GET TERMS DATA
        getTermsData() {
            axios
                .get("policy", {
                    headers: {
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.termsText = res.data.data.policy;
                });
        },
        // END:: GET TERMS DATA
    },

    mounted() {
        // START:: GET TERMS DATA
        this.getTermsData();
        // END:: GET TERMS DATA
    },
};
</script>

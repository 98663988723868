<template>
  <header class="">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-6 col-lg-1">
          <div class="logoSite">
            <router-link to="/">
              <img
                src="../../../assets/images/logos/logo.png"
                alt=" Site Logo"
              />
            </router-link>
          </div>
        </div>
        <!--  -->
        <div class="col-6 col-lg-11 d-flex justify-content-end">
          <button class="btn open_menu_bn" @click="showMobileMenu">
            <i class="fas fa-ellipsis-h fa-lg"></i>
          </button>

          <div class="small_screens_nav_bar">
            <div class="btn_wraper">
              <button
                class="btn text-danger close_menu"
                @click="hideMobileMenu"
              >
                <i class="fas fa-times fa-2x"></i>
              </button>
            </div>

            <ul>
              <li>
                <router-link to="/"> {{ $t("home") }} </router-link>
              </li>
              <li>
                <router-link to="/"> {{ $t("about_app") }} </router-link>
              </li>
              <li>
                <router-link to="/">
                  {{ $t("terms_&_conditions") }}
                </router-link>
              </li>
              <li>
                <router-link to="/"> {{ $t("contact_us") }} </router-link>
              </li>

              <li v-if="userExist">
                <router-link to="/dashboard" class="btn-sign-in">
                  {{ $t("dashboard") }}
                </router-link>
              </li>

              <li v-else>
                <router-link to="/login" class="btn-sign-in">
                  {{ $t("sign_in") }}
                </router-link>
              </li>
            </ul>
          </div>

          <div class="nav-bar-site">
            <ul class="d-flex justify-content-end">
              <li>
                <router-link to="/"> {{ $t("home") }} </router-link>
              </li>
              <li>
                <router-link to="/"> {{ $t("about_app") }} </router-link>
              </li>
              <li>
                <router-link to="/">
                  {{ $t("terms_&_conditions") }}
                </router-link>
              </li>
              <li>
                <router-link to="/"> {{ $t("contact_us") }} </router-link>
              </li>

              <li v-if="userExist && userAccepted != 0">
                <router-link to="/dashboard" class="btn-sign-in">
                  {{ $t("dashboard") }}
                </router-link>
              </li>

              <li v-if="!userExist || userAccepted == 0">
                <router-link to="/login" class="btn-sign-in">
                  {{ $t("sign_in") }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      userExist: "",
      userAccepted: localStorage.getItem("accepted"),
    };
  },

  methods: {
    showMobileMenu() {
      document
        .querySelector(".small_screens_nav_bar")
        .classList.add("open_menu");
    },

    hideMobileMenu() {
      document
        .querySelector(".small_screens_nav_bar")
        .classList.remove("open_menu");
    },
  },

  mounted() {
    let user_token = localStorage.getItem("user_token");
    // let user_is_accepted = localStorage.getItem("accepted");
    if (user_token) {
      this.userExist = true;
    } else {
      this.userExist = false;
    }
  },
};
</script>

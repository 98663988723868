<template>
    <div id="mainDashboardSection">
        <dashboard-header></dashboard-header>

        <div class="all-content">
            <transition name="slither" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
import DashboardHeader from "@/components/pages/headerDashboard/DashboardHeader";

export default {
    name: "mainDashboard",
    components: {
        DashboardHeader,
    },

    mounted() {
        let user_is_accepted = localStorage.getItem("accepted");
        if (user_is_accepted == 0) {
            this.$router.push("/login");
        }
    },
};
</script>

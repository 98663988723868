<script>
import { HorizontalBar } from "vue-chartjs";

export default {
  extends: HorizontalBar,

  props: ["chartData"],

  mounted() {
    this.renderChart({
      labels: this.chartData.labels,

      datasets: [
        {
          barThickness: 10,
          maxBarThickness: 10,
          axis: "y",
          label: "",
          data: this.chartData.datasets,
          fill: false,
          backgroundColor: this.chartData.backgroundColor,
          borderColor: this.chartData.borderColor,
          borderWidth: 1,
        },
      ],

      options: {
        plugins: {
          legend: {
            display: false,
          },

          title: {
            display: false,
          },
        },
      },
    });
  },
};
</script>

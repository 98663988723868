<template>
    <div>
        <HeaderSite />
        <div class="Login-page">
            <section id="authSiteWraper">
                <div class="container">
                    <form @submit.prevent="validateResetPasswordPhoneNumber">
                        <div class="form-tab crud-auth padding-all-15">
                            <div class="text-form-input">
                                <p>{{ $t("enter_your_phone") }}</p>
                            </div>

                            <div class="row">
                                <!-- START:: MOBILE NUMBER -->
                                <div class="col-lg-12">
                                    <div class="form-group form-group-custom">
                                        <!-- Dropdown  -->
                                        <div class="dropdown-countries">
                                            <div
                                                class="
                                                    number-show
                                                    d-flex
                                                    align-items-center
                                                    justify-content-center
                                                "
                                                @click="showNmber = !showNmber"
                                            >
                                                <img
                                                    :src="selectedCountry.flag"
                                                    alt=""
                                                />
                                                <i
                                                    class="fas fa-chevron-down"
                                                ></i>
                                                <p>{{ selectedCountry.key }}</p>
                                            </div>

                                            <transition
                                                name="fade"
                                                mode="out-in"
                                            >
                                                <ul v-if="showNmber">
                                                    <li
                                                        v-for="country in countries"
                                                        :key="country.id"
                                                        @click="
                                                            chooseKey(
                                                                country.key,
                                                                country.flag
                                                            )
                                                        "
                                                        class="
                                                            d-flex
                                                            align-items-center
                                                            justify-content-between
                                                        "
                                                    >
                                                        <img
                                                            :src="country.flag"
                                                            width="40px"
                                                            height="25px"
                                                        />
                                                        <p>
                                                            +{{ country.key }}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </transition>
                                        </div>
                                        <!-- Dropdown  -->
                                        <input
                                            type="tel"
                                            class="form-control mobile-input"
                                            v-model.trim="resetPassPhone"
                                            placeholder="5XXXXXXXX"
                                            name="mobile"
                                        />
                                        <label class="label-form">
                                            {{ $t("mobile_number") }}
                                        </label>
                                    </div>
                                </div>
                                <!-- END:: MOBILE NUMBER -->

                                <div class="col-lg-12">
                                    <div class="main-btn m-0">
                                        <button>
                                            {{ $t("save") }}
                                            <span
                                                class="
                                                    spinner-border
                                                    spinner-border-sm
                                                "
                                                role="status"
                                                aria-hidden="true"
                                                v-if="isWaiting"
                                            ></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import HeaderSite from "@/components/pages/Header/HeaderSite";

export default {
    name: "ResetPasswordPhoneNumber",

    components: {
        HeaderSite,
    },

    data() {
        return {
            isWaiting: false,
            showNmber: false,
            countries: [],
            selectedCountry: {
                key: "",
                flag: "",
            },
            resetPassPhone: null,
        };
    },

    methods: {
        chooseKey(key, flag) {
            this.showNmber = false;
            this.selectedCountry = {
                key,
                flag,
            };
            this.resetPassPhone = null;
        },

        getCountriesKies() {
            axios
                .get("countries", {
                    headers: {
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    this.countries = res.data.data;

                    this.selectedCountry.key = res.data.data[0].key;
                    this.selectedCountry.flag = res.data.data[0].flag;
                });
        },

        // START:: VALIDATE RESET PASSWORD PHONE INPUT
        validateResetPasswordPhoneNumber() {
            if (this.resetPassPhone == null) {
                this.$iziToast.error({
                    message: "Phone Number Can't Be Empty",
                    position: "bottomCenter",
                    rtl: true,
                });
                return;
            } else {
                this.submitChangePasswordPhoneNumber();
            }
        },
        // END:: VALIDATE RESET PASSWORD PHONE INPUT

        // START:: SUBMIT CHANGE PASSWORD PHONE NUMBER FORM
        submitChangePasswordPhoneNumber() {
            this.isWaiting = true;
            let theData = new FormData();
            theData.append(
                "phone",
                this.selectedCountry.key + this.resetPassPhone
            );

            axios
                .post("send_code", theData, {
                    headers: {
                        "Accept-Language":
                            localStorage.getItem("dashboard_lang"),
                        "cache-control": "no-cache",
                        "Content-type": `multipart/form-data;`,
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.status) {
                        this.$iziToast.success({
                            message: res.data.message,
                            position: "bottomCenter",
                            rtl: true,
                        });
                        this.isWaiting = false;
                        this.$router.replace("/verification/reset-password");
                        localStorage.setItem("reset_ver_code", res.data.dev_message)
                        localStorage.setItem("reset_pass_phone", this.selectedCountry.key + this.resetPassPhone)
                    }
                })
                .catch( error => {
                    console.log(error);
                    this.isWaiting = false;
                })
        },
        // END:: SUBMIT CHANGE PASSWORD PHONE NUMBER FORM
    },

    mounted() {
        this.getCountriesKies();
    },
};
</script>

<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" class="border-bottom border-white">Product Name</th>
        <th scope="col" class="border-bottom border-white">Number of Sales</th>
        <th scope="col" class="border-bottom border-white">Branch Name</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="item in chartData[1]" :key="item.id">
        <td>{{ item.product_name }}</td>
        <td>{{ item.number_of_sales }}</td>
        <td>{{ item.branch_name }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["chartData"],
};
</script>

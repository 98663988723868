var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('HeaderSite'),_c('div',{staticClass:"Login-page"},[_c('section',{attrs:{"id":"authSiteWraper"}},[_c('div',{staticClass:"container"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateResetPasswordInputs.apply(null, arguments)}}},[_c('div',{staticClass:"form-tab crud-auth padding-all-15"},[_c('div',{staticClass:"text-form-input"},[_c('h3',[_vm._v(_vm._s(_vm.$t("reset_password")))])]),_c('div',{staticClass:"row p-3 p-md-0"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group form-group-custom"},[(
                                            _vm.new_password_visibility ==
                                            'password'
                                        )?_c('span',{staticClass:"showPassword",on:{"click":function($event){return _vm.showPassword('new_password')}}}):_c('span',{staticClass:"hidePassword",on:{"click":function($event){return _vm.hidePassword('new_password')}}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                            _vm.resetPassData.newPassword
                                        ),expression:"\n                                            resetPassData.newPassword\n                                        ",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":_vm.new_password_visibility,"name":"password"},domProps:{"value":(
                                            _vm.resetPassData.newPassword
                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.resetPassData, "newPassword", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{staticClass:"label-form"},[_vm._v(" "+_vm._s(_vm.$t("new_password"))+" ")])])]),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group form-group-custom"},[(
                                            _vm.confirm_new_password_visibility ==
                                            'password'
                                        )?_c('span',{staticClass:"showPassword",on:{"click":function($event){return _vm.showPassword(
                                                'confirm_new_password'
                                            )}}}):_c('span',{staticClass:"hidePassword",on:{"click":function($event){return _vm.hidePassword(
                                                'confirm_new_password'
                                            )}}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                            _vm.resetPassData.confirmNewPassword
                                        ),expression:"\n                                            resetPassData.confirmNewPassword\n                                        ",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"confirm_new_password","type":_vm.confirm_new_password_visibility,"name":"password"},domProps:{"value":(
                                            _vm.resetPassData.confirmNewPassword
                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.resetPassData, "confirmNewPassword", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{staticClass:"label-form"},[_vm._v(" "+_vm._s(_vm.$t("confirm_new_pass"))+" ")])])]),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"main-btn m-0"},[_c('button',[_vm._v(" "+_vm._s(_vm.$t("save"))+" "),(_vm.isWaiting)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()])])])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }